import { axios } from "@/utils/request";

// 1070105 获取签名
export function getSign(data) {
  return axios({
    url: '/device/getSign',
    method: 'post',
    data
  })
}

// 1070101 设备信息
export function getDevice(data) {
  return axios({
    url: '/device/getDevice',
    method: 'post',
    data
  })
}

// 2020303 获取我的可用优惠券列表根据门店产品
export function getCustomerCouponByShopProduct(data) {
  return axios({
    url: '/customerCoupon/getCustomerCouponByShopProduct',
    method: 'post',
    data
  })
}
